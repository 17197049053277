import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"



const InsurancesPage = (props) => {
    return (
        <Layout >
            <SEO schemaData={'Insurances'} title={'Insurances'} />
            <div className="px-4 mx-auto max-w-3xl text-navy-500 text-left mb-8">
                <h1 className="text-center uppercase text-xl lg:my-2 font-bold my-4 lg:mb-12">Insurances Accepted</h1>
                <p className=" mb-4 lg:mb-12" >
                    <span className="font-bold">This is a medical optometry practice.</span>
                    The Doctors and Staff would like to assist you in maintaining your visual health. The Doctors have several years of medical optometry experience and are licensed to diagnose, manage, and treat disorders of the eye. Our office offers routine eye examinations for glasses prescriptions, contact lens fittings and follow ups. As well as diabetic eye examinations, treatment of allergies, red eyes, infections, dry eye, management of glaucoma and macular degeneration and other eye diseases.
                </p>
                <h2 className="text-lg mt-2 lg:my-2 font-thin mb-4">Presently we are providers for the following medical health plans ONLY:</h2>
                <h3 className="font-bold text-lg">Medicare</h3>
                <ul className="mb-4 list-disc">
                    <li className="ml-6">Medicare Replacement (i.e. Humana Gold, Care Improvement Plus, Universal Healthcare, etc)</li>
                    <li className="ml-6">Medicare Supplemental Plans</li>
                </ul>
                <h3 className="font-bold text-lg">Medicaid</h3>
                <ul className="mb-4 list-disc">
                    <li className="ml-6">Amerigroup</li>
                    <li className="ml-6">Medicare Supplemental Plans</li>
                    <li className="ml-6">Wellcare</li>
                    <li className="ml-6">Peachstate</li>
                    <li className="ml-6">Peachcare</li>
                    <li className="ml-6">Caresource</li>
                </ul>
                <h3 className="font-bold text-lg">Medical Insurance Plans:</h3>
                <ul className="mb-4 list-disc">
                    <li className="ml-6">Coventry/Southcare/First Health Network</li>
                    <li className="ml-6">PHCS/Multiplan</li>
                    <li className="ml-6">United Health Care</li>
                    <li className="ml-6">VA Vouchers</li>
                    <li className="ml-6">Blue Cross Blue Shield (PPO, POS, HMO and FEP)</li>
                    <li className="ml-6">Tricare Military Humana Military</li>
                    <li className="ml-6">Private Health Care Systems (PHCS)</li>
                    <li className="ml-6">Beechstreet</li>
                    <li className="ml-6">Supermed Network PPO</li>
                    <li className="ml-6">Cigna</li>
                    <li className="ml-6">Humana</li>
                    <li className="ml-6">Logistics Health Incorporated (LHI)</li>
                </ul>
                <p className="bg-navy-50 p-4 text-navy-600 rounded mb-4 lg:mb-12">
                    If you have any of these insurances, we are required by your insurance company to have a copy of your insurance card at the time of service. If you have any other insurance, we will gladly provide the proper ophthalmic care you need. You will then be able to file your claim as “out of network”. Do not forget to ask for the super bill to submit to your insurance.
                </p>
                <h2 className="text-center uppercase text-lg mt-2 lg:my-2 font-thin mb-4 "> Presently we are providers for the following routine vision plans:</h2>
                <h3 className="font-bold text-lg">Vision Insurance Plance:</h3>
                <ul className="mb-4 list-disc">
                    <li className="ml-6">VSP</li>
                    <li className="ml-6">Eyemed</li>
                    <li className="ml-6">Spectera</li>
                    <li className="ml-6">Davis Vision</li>
                    <li className="ml-6">Superior Vision</li>
                    <li className="ml-6">Block Vision</li>
                    <li className="ml-6">Avesis Vision</li>
                    <li className="ml-6">Medical Eye Services (MES)</li>
                    <li className="ml-6">National Vision Administrators (NVA)</li>
                    <li className="ml-6">Advantica</li>
                    <li className="ml-6">Starmount/Always Vision</li>
                    <li className="ml-6">Logistics Health Incorporated (LHI)</li>
                    <li className="ml-6">Community Eye Care (CEC)</li>
                    <li className="ml-6">Bridgemart</li>
                </ul>
                <div className="bg-navy-50 p-4 rounded mb-4 text-navy-600">
                    <p className="mb-2">If you do not see your routine vision provider listed here, ask to see if we accept yours.</p>
                    <p>These routine vision plans do not cover “red eyes” or other medical examinations.</p>
                </div>
                <p className="mb-2">As with all medical practices, fees are dependent upon level of service provided. Prices quoted over the phone are just a baseline price. Medicare establishes medical fee guidelines and this office follows those guidelines. Your final bill may be more than an advertised “routine eye exam.”</p>
                <p><span className="font-bold">Routine eye exams are defined as:</span> An examination of the visual field void of any medical eye conditions such as itchy eyes, diabetes, glaucoma, macular degeneration, cataracts, etc.</p>

            </div>
        </Layout>
    )
}

export default InsurancesPage
